:root {
    --primary-color: #242424;
    --secondary-color: #5588a3;
    --quaternary-color: #1f1f1f;
    --tertiary-color: #c7d1d8;
}

.c-bg-primary{
    background-color: var(--primary-color);
}

.c-bg-secondary{
    background-color: var(--secondary-color);
}

.c-text-primary{
    color: var(--primary-color);
}

.c-text-secondary{
    color: var(--secondary-color);
}

.c-text-tertiary{
    color: var(--tertiary-color);
}

.c-text-quaternary{
    color: var(--quaternary-color);
}

.c-githubCard{
    background-color: var(--secondary-color);
    color: var(--tertiary-color);
    text-align: center;
    width: 100% ;
}
