.c-gh-card-body {
    padding: 0;
    height: 15rem;
}

.c-gh-card-statbar {
    display: block;
    float: right;
    border-color: var(--tertiary-color);
    border-left-style: solid;
    height: 100%
}

.c-gh-card {
    background-color: var(--primary-color);
    color: var(--tertiary-color);
    text-align: center;
    padding: 0;
    width: 22rem;
    height: 18rem;
}

.c-gh-card-title {
    border-bottom-style: none;
    border-bottom-width: 19px;
    border-bottom-style: solid;
    border-bottom-width: 6px;
    margin-bottom: 0px;
    height: 50px;
}

.c-gh-card-text {
    float: left;
    padding: 0 10px 10px 10px;
}

.c-gh-card-tag {
    margin: 1px;
}

.c-gh-card-tag:hover {
    color: var(--tertiary-color)
}

.c-gh-card-tag-container {
    margin-top: 10px;
    overflow: hidden;
    flex-wrap: wrap;
}

.c-gh-card-icon {
    height: 33.3%;
    border-radius: 0;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    width: 100%;

    background-color: inherit;
    border-color: inherit;
}


.c-gh-card-icon:last-child {
    display: block;
    border-width: 0;
    border-radius: 0 0 var(--bs-border-radius) 0;
    color: inherit;
}

.c-gh-card-icon:last-child>a {
    color: inherit;
}

.c-gh-card-icon:last-child:hover {
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);
}

.c-gh-card-description-container p a {
    text-decoration: none;
    color: var(--secondary-color);
}


.c-gh-card-description-container p a:hover,
.c-gh-card-description-container p a:focus {
    text-decoration: none;
    opacity: 0.4;
}

.c-gh-card-description-container p a:visited {
    background-color: aqua;
}