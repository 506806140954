@import "../styles/colors.css";


.c-home-card {
    text-align: center;
    padding: 50px;
    height: 20em;
    width: 40em;
    margin: auto;
    top: 25vh;
}

.c-home-card-title {
    font-size: xx-large;
}

.media-button {
    margin: 0.5em;
    padding: 5px 25px;
    background-color: var(--primary-color);
    color: var(--secondary-color);
    border: var(--tertiary-color) 1px;
    width: 5em;
}


.media-button:hover {
    background-color: var(--primary-color);
    border: var(--primary-color) 1px;
    color: var(--tertiary-color);
}

.card {
    -webkit-box-shadow: 3px 3px 20px 1px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 3px 3px 20px 1px rgba(0, 0, 0, 0.75);
    box-shadow: 3px 3px 20px 1px rgba(0, 0, 0, 0.75);
}

@media screen and (max-width: 1024px) {
    .home-page {
        left: 0;
        margin: 0 25px;
        padding-left: 0;
        width: auto;
        position: initial;
    }

    .c-home-card {
        text-align: center;
        padding: 50px;
        height: 20rem;
        width: 20em;
        top: 5rem;
    }
}