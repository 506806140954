@import "./colors.css";

:root {
    --left-nav-width: 6rem;
    --short-nav-height: 0;
}

html {
    overflow: hidden;
}

body {
    background-color: var(--quaternary-color);
    font-family: "Avenir Next";
    color: var(--tertiary-color);
    overflow-x: clip;
}


.center {
    display: flex;
    justify-content: center;
    align-items: center;
}


.text-left-align {
    text-align: left;
}

*:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
}

* {
    scrollbar-width: thin;
    scrollbar-color: #333 #f5f5f5;
}

.hide {
    display: none;
}

main {
    width: calc(100vw - var(--left-nav-width));
    position: absolute;
    top: var(--short-nav-height);
    margin-top: 1.25rem;
    height: calc(100vh - 1.25rem);
    left: var(--left-nav-width);
    overflow-y: scroll;
    padding-left: 1rem;
    padding-right: 1rem;
}

h1 {
    color: var(--secondary-color)
}