@import "../styles/colors.css";

.card {
    -webkit-box-shadow: 3px 3px 20px 1px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 3px 3px 20px 1px rgba(0, 0, 0, 0.75);
    box-shadow: 3px 3px 20px 1px rgba(0, 0, 0, 0.75);
}

.files-container {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.files-container .card{
    padding: 5px;
    margin: 5px;
}