.navbar-link {
    text-align: center;
}

.navbar-toggler {
    float: right;
}

.secondary-color,
.gradientNav,
.gradientNavLink,
.dropdown-menu,
.shortNav,
.fullNav {
    background: var(--primary-color);
    color: var(--tertiary-color);
}

.gradientNav,
.gradientNavLink:hover,
.gradientNavLink:active {
    color: var(--secondary-color);
}

.nav {
    float: right;
    position: absolute;
    width: 25px;
}

.c-nav-link:focus {
    color: var(--secondary-color);
}

.nav-link-text {
    font-size: large;
}

.nav-link-icon {
    font-size: x-large;
}

.shortNav {
    display: none;
    height: var(--short-nav-height);
    z-index: 99999;
    position: relative;
}

.shortNav>.navbar-brand,
.shortNav-link,
.shortNav>.navbar-toggler {
    margin: 0 1em;
}

.fullNav {
    height: 100vh;
    display: inherit;
    margin: 0;
    width: var(--left-nav-width);
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: var(--primary-color);
    overflow-x: hidden;
    padding-top: 20px;
}

.fullNav a {
    padding: 20px 6px 6px 6px;
    text-decoration: none;
    text-align: center;
    color: var(--secondary-color);
    display: block;
}

.fullNav a:hover,
.fullNav a.active {
    color: var(--tertiary-color);
    background-color: var(--secondary-color);
}

.fullNav a.active {
    transform: none;
}

a.navbar-brand,
.brand-link>p {
    margin: 0;
    width: 10rem;
    font-family: "Courier New", Courier, monospace;
}

@media screen and (max-width: 1024px) {
    .shortNav {
        display: inherit !important;
    }

    .fullNav {
        display: none !important;
    }

    :root {
        --left-nav-width: 0 !important;
        --short-nav-height: 3rem !important;
    }
}